/**
 * QuillForms Dependencies
 */
import { useTheme } from '@quillforms/renderer-core';
import { registerBlockType } from '@quillforms/blocks';
/**
 * External Dependencies
 */
import { FileRejection, useDropzone } from 'react-dropzone';
import tinyColor from 'tinycolor2';
import { css } from 'emotion';
import { FC, FunctionComponent, useEffect, useRef, useState } from 'react';
import { FileData, useFileUploadBlockStore } from './state';
import UploadAreaContent from './uploadAreaContent';
import axios from 'axios';

enum ParseReportStatus {
  RECEIVED = 'RECEIVED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

const FileUploadBlockDisplay: FunctionComponent<{
  id: string;
  attributes: {
    multiple: boolean;
    maxFileSize: number;
    allowedFileExtensions: string;
    required: boolean;
  };
  setIsValid: (isValid: boolean) => void;
  isPreview: boolean;
  setIsAnswered: (isAnswered: boolean) => void;
  setIsPending: (isPending: boolean) => void;
  setPendingMsg: (pendingMessage: string) => void;
  setValidationErr: (validationError: Error) => void;
  blockWithError: (message: string) => void;
  showNextBtn: any;
  showErrMsg: any;
  val: any;
  setVal: any;
  isTouchScreen: boolean;
}> = ({
  id,
  attributes,
  setIsValid,
  setIsAnswered,
  setIsPending: setBlockPending,
  blockWithError,
  showNextBtn,
  showErrMsg,
}) => {
  const { files, updateFile, addFile } = useFileUploadBlockStore(
    ({ files, updateFile, addFile }) => ({ files, updateFile, addFile })
  );

  const [parseReport, setParseReport] = useState<{
    status: ParseReportStatus;
    progress: number;
    error?: string;
    report?: Record<string, unknown>;
  } | null>(null);

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const theme = useTheme();
  const answersColor = tinyColor(theme.answersColor);

  const { multiple, maxFileSize, allowedFileExtensions } = attributes;

  const accept: string = allowedFileExtensions.trim()
    ? allowedFileExtensions
        .trim()
        .split(',')
        .filter((ext) => ext.trim())
        .map((ext) => `.${ext.trim()}`)
        .join(',')
    : '';

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setBlockPending(isUploading);
  }, [isUploading]);

  useEffect(() => {
    if (Object.keys(files).length > 0 && !isUploading) {
      setIsAnswered(true);
      showNextBtn(true);
      setIsValid(true);
      return;
    }

    setIsValid(false);
    setIsAnswered(false);
    showNextBtn(false);
  }, [isUploading, files]);

  const onDropAccepted = (files: File[]) => {
    for (const file of files) {
      return upload(file);
    }
  };

  const upload = async (file: File) => {
    const fileKey = Math.random().toString(36).substr(2, 10);

    const formData = new FormData();
    // formData.append('key', `${fileKey}.pdf`);
    formData.append('Content-Type', file.type);
    formData.append('file', file);

    setIsUploading(true);
    showErrMsg(false);

    const fileData: FileData = {
      status: 'pending',
      progress: 0,
      file,
      name: file.name,
      size: file.size,
      type: file.type,
      previewUrlSrc: URL.createObjectURL(file),
    };

    addFile(fileKey, fileData);

    axios
      .request({
        method: 'put',
        baseURL: `https://unsafe-public-885167583412-deleteme.s3.amazonaws.com/${fileKey}.pdf`,
        data: formData,
        headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'multipart/form-data' },
        onUploadProgress(e) {
          console.log(`Progress: ${(e.loaded / (e.total ?? Number.POSITIVE_INFINITY)) * 100}%`);

          updateFile(fileKey, {
            progress: (e.loaded / (e.total ?? Number.POSITIVE_INFINITY)) * 100,
          });
        },
      })
      .then(({ status, data }) => {
        console.log('load');

        let fileData: Partial<FileData>;
        if (status >= 200 && status < 300) {
          fileData = {
            status: 'success',
            progress: 100,
          };

          setIsAnswered(true);
        } else {
          fileData = {
            status: 'error',
            progress: 100,
          };
          console.error(data ?? 'Cannot upload file');
        }

        setIsUploading(false);
        updateFile(fileKey, fileData);
      })
      .catch((e) => {
        updateFile(fileKey, {
          status: 'error',
          progress: 100,
        });
        console.error(e.message ?? 'Cannot upload file');
      });
  };

  const onDropRejected = (files: FileRejection[]) => {
    // We just need to show the first error only
    if (files[0].errors[0].code === 'file-too-large') {
      return blockWithError(`File is larger than ${maxFileSize} MB`);
    }

    blockWithError(files[0].errors[0].message);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: multiple,
    maxSize: maxFileSize * 1024 * 1024,
    accept,
    onDropAccepted,
    onDropRejected,
    disabled: multiple ? false : Object.keys(files).length > 0,
    maxFiles: multiple ? 4 : 1,
  });

  return (
    <div className="question__wrapper">
      <div
        {...getRootProps({ className: 'dropzone' })}
        className={css`
          & {
            display: flex;
            flex-wrap: wrap;
            margin-top: 15px;
            width: 100%;
            min-height: 300px;
            max-height: 300px;
            overflow-y: auto;
            outline: none;
            cursor: pointer;
            background: ${answersColor.setAlpha(0.1).toString()};
            border: 1px dashed ${answersColor.setAlpha(0.8).toString()};

            &:hover {
              background: ${answersColor.setAlpha(0.2).toString()};
            }
          }
        `}
        ref={wrapperRef}
      >
        <input {...getInputProps()} />
        <UploadAreaContent
          attributes={{ maxFileSize }}
          blockId={id}
          multiple={multiple}
          isUploading={isUploading}
        />
      </div>
    </div>
  );
};

registerBlockType('file-upload', {
  attributes: {
    maxFileSize: {
      type: 'number',
      default: 1,
    },
    allowedFileExtensions: {
      type: 'string',
      default: '',
    },
    multiple: {
      type: 'boolean',
      default: false,
    },
  },
  supports: {
    required: true,
    editable: true,
    logic: true,
    logicConditions: false,
    attachment: true,
  },
  display: FileUploadBlockDisplay as FC,
});
