import en from './en';

export const translationResources = {
  en: {
    translation: en,
  },
};

export const languageOptions: {
  [key in keyof typeof translationResources]: {
    live: boolean;
    nativeName: string;
  };
} = {
  en: {
    live: true,
    nativeName: 'English',
  },
};
