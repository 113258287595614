import { FunctionComponent } from 'react';
import { Form } from '@quillforms/renderer-core';
import '@quillforms/renderer-core/build-style/style.css';
// @ts-expect-error Doesn't support TS
import { registerCoreBlocks } from '@quillforms/react-renderer-utils';
import '../components/customBlocks';
registerCoreBlocks();

const Dashboard: FunctionComponent = () => {
  return (
    <Form
      formId={1}
      formObj={{
        blocks: [
          {
            id: 'welcome',
            name: 'welcome-screen',
            attributes: {
              label: `Welcome`,
              description: `Let's clean up that resume and make sure it gets to a real human.`,
              buttonText: 'Show me what you got',
            },
          },
          {
            id: 'current-resume',
            name: 'file-upload',
            attributes: {
              label: `Let's see what we're working with`,
              description: 'Upload your latest resume to use as a starting point',
              required: true,
              allowedFileExtensions: 'pdf,doc,docx',
              maxFileSize: 10,
            },
          },
          {
            id: 'processing',
            name: 'long-text',
            attributes: {
              label: `Processing...`,
              description: `Next`,
              buttonText: 'Show me what you got',
            },
          },
        ],
      }}
      applyLogic={true}
      onSubmit={({}) => {
        throw new Error('');
      }}
    />
  );
};

export default Dashboard;
