import { FunctionComponent, useState, MouseEvent } from 'react';
import UserAvatar from './UserAvatar';
import { IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@mui/material';
import { Login, Logout } from '@mui/icons-material';
import { AUTH_STATE, useAuthStore } from '../../state/authentication';
import { useModalStateStore } from '../../state/modals';

const UserMenu: FunctionComponent<unknown> = () => {
  const { authState, logout } = useAuthStore(({ authState, logout }) => ({ authState, logout }));
  const { openAuthModal } = useModalStateStore(({ openAuthModal }) => ({
    openAuthModal,
  }));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <UserAvatar />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
      >
        {authState === AUTH_STATE.AUTHENTICATED ? (
          <MenuItem
            onClick={() => {
              logout();
              handleClose();
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              openAuthModal();
              handleClose();
            }}
          >
            <ListItemIcon>
              <Login fontSize="small" />
            </ListItemIcon>
            Login
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default UserMenu;
