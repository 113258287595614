import { useModalStateStore } from '../../state/modals';

export type MenuLink = { label: string } & (
  | { link: string }
  | { action: () => void }
  | { submenu: MenuLink[] }
);

export const useMenuConfig = (onAfter: () => void = () => {}) => {
  const { openContactUsModal, openNewBookingModal } = useModalStateStore(
    ({ openContactUsModal, openNewBookingModal }) => ({
      openContactUsModal,
      openNewBookingModal,
    })
  );

  const buttons: MenuLink[] = [];
  //  [
  //   {
  //     label: 'newBooking',
  //     action: () => {
  //       openNewBookingModal();
  //       onAfter();
  //     },
  //   },
  //   {
  //     label: 'contact',
  //     action: () => {
  //       openContactUsModal();
  //       onAfter();
  //     },
  //   },
  // ];

  return { buttons };
};
