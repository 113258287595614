import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type FileData = {
  status: 'pending' | 'success' | 'error';
  file: File;
  progress: number;
  name: string;
  type: string;
  size: number;
  previewUrlSrc?: string;
};

interface FileUploadBlockState {
  files: { [k: string]: FileData };

  addFile: (id: string, file: FileData) => void;
  updateFile: (id: string, file: Partial<FileData>) => void;
  deleteFile: (id: string) => void;
  reset: () => void;
}

export const useFileUploadBlockStore = create<FileUploadBlockState>()(
  devtools((set) => ({
    files: {},

    addFile(id: string, file: FileData) {
      set(({ files }) => ({
        files: {
          ...files,
          [id]: file,
        },
      }));
    },

    updateFile(id: string, file: Partial<FileData>) {
      set(({ files: { [id]: fileToChange, ...files } }) => ({
        files: {
          ...files,
          [id]: {
            ...fileToChange,
            ...file,
          },
        },
      }));
    },

    deleteFile(id: string) {
      set(({ files: { [id]: _, ...files } }) => ({
        files: {
          ...files,
        },
      }));
    },

    reset() {
      set({ files: {} });
    },
  }))
);
