import { Avatar, AvatarProps, Badge, BadgeProps } from '@mui/material';
import { FunctionComponent } from 'react';
// import { useUserStore } from '../../state/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAstronaut } from '@fortawesome/free-solid-svg-icons';

const UserAvatar: FunctionComponent<{
  avatarProps?: AvatarProps;
  badgeProps?: BadgeProps;
}> = ({ avatarProps, badgeProps }) => {
  // const { currentUser } = useUserStore(({ currentUser }) => ({ currentUser }));

  return (
    <Badge
      overlap="circular"
      variant="dot"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      {...badgeProps}
    >
      {/* <Avatar
        alt={[currentUser?.firstName, currentUser?.lastName].filter(Boolean).join(' ')}
        sx={{ bgcolor: '#2D566B' }}
        {...avatarProps}
      >
        <FontAwesomeIcon icon={faUserAstronaut} />
      </Avatar> */}
    </Badge>
  );
};

export default UserAvatar;
