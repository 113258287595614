import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface ModalState {
  contactUsOpen: boolean;
  openContactUsModal: () => void;
  closeContactUsModal: () => void;

  newBookingModalOpen: boolean;
  openNewBookingModal: () => void;
  closeNewBookingModal: () => void;

  authModalOpen: boolean;
  openAuthModal: () => void;
  closeAuthModal: () => void;
}

export const useModalStateStore = create<ModalState>()(
  devtools((set) => ({
    contactUsOpen: false,
    openContactUsModal: () => set({ contactUsOpen: true }),
    closeContactUsModal: () => set({ contactUsOpen: false }),

    newBookingModalOpen: false,
    openNewBookingModal: () => set({ newBookingModalOpen: true }),
    closeNewBookingModal: () => set({ newBookingModalOpen: false }),

    authModalOpen: false,
    openAuthModal: () => set({ authModalOpen: true }),
    closeAuthModal: () => set({ authModalOpen: false }),
  }))
);
