import { useTheme, useMessages } from '@quillforms/renderer-core';
import tinyColor from 'tinycolor2';
import { css } from 'emotion';
import { size } from 'lodash';
import classnames from 'classnames';
import { FC, useEffect } from 'react';
import { FileData, useFileUploadBlockStore } from './state';

/**
 *
 */
const SingleFileInfo: FC<{
  fileData: FileData;
  borderColor: string;
  color: string;
  fileKey: string;
  onDelete: (id: string) => void;
}> = ({ fileData, borderColor, color, fileKey, onDelete }) => {
  return (
    <div
      className={css`
        padding: 8px;
        border-top: 1px solid ${borderColor};
        font-size: 11px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: ${color};
      `}
    >
      <div>{fileData.name} </div>
      <div
        className={css`
          margin-left: 5px;
        `}
        role="button"
        tabIndex={-1}
        onClick={() => {
          onDelete(fileKey);
        }}
      >
        <svg
          focusable="false"
          viewBox="0 0 24 24"
          role="presentation"
          className={css`
            width: 20px;
            height: 20px;
            fill: ${color};
          `}
        >
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        </svg>
      </div>
    </div>
  );
};

/**
 *
 */
const ProgressBar: FC<{ progress: number }> = ({ progress }) => {
  const theme = useTheme();
  const answersColor = tinyColor(theme.answersColor);

  console.log(progress);

  return (
    <div
      className={css`
        position: relative;
        height: 7px;
        border-radius: 3px;
        overflow: hidden;
        width: 200px;
        margin-top: 15px;
        border-radius: 2px;
        background: ${answersColor.setAlpha(0.4).toString()};
      `}
    >
      <div
        className={css`
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          transition: width 0.3s;
          -webkit-transition: width 0.3s;
          -moz-transition: width 0.3s;
          -o-transition: width 0.3s;

          width: ${progress}%;
          background: ${answersColor.setAlpha(1).toString()};
        `}
      />
    </div>
  );
};

/**
 *
 */
const FileIcon: FC<{ color: string; width?: number; height?: number }> = ({
  color,
  width = 90,
  height = 90,
}) => {
  return (
    <svg
      className={css`
        fill: ${color};
      `}
      strokeWidth="0"
      viewBox="0 0 1024 1024"
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494z"></path>
    </svg>
  );
};

/**
 *
 */
const UploadAreaContent: FC<{
  blockId: string;
  attributes: { maxFileSize: number };
  multiple: boolean;
  isUploading: boolean;
}> = ({ blockId, attributes, multiple, isUploading }) => {
  // const humanFileSize = (bytes: number) => {
  //   let i = Math.floor(Math.log(bytes) / Math.log(1000));
  //   return (bytes / Math.pow(1000, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  // };

  const isPending = isUploading;

  const { files, deleteFile } = useFileUploadBlockStore(({ files, deleteFile }) => ({
    files,
    deleteFile,
  }));

  const theme = useTheme();
  const messages = useMessages();

  // Just add active class to have a basic animation
  useEffect(() => {
    if (!multiple && Object.entries(files)?.length) {
      setTimeout(() => {
        document
          .querySelector(`.blocklib-file-block-${blockId}-display__uploaded-file`)
          ?.classList?.add('active');
      }, 50);
    }
  }, [files]);

  const answersColor = tinyColor(theme.answersColor);
  return (
    <div
      className={css`
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100%;
        width: 100%;
        padding: 20px;
      `}
    >
      {size(files) === 0 || isPending ? (
        <div
          className={css`
            position: relative;
          `}
        >
          <>
            <svg
              className={css`
                fill: ${answersColor.setAlpha(0.3).toString()};
              `}
              width="115"
              viewBox="0 0 24 24"
            >
              <path d="M0 0h24v24H0V0z" fill="none"></path>
              <path d="M12 6c2.62 0 4.88 1.86 5.39 4.43l.3 1.5 1.53.11c1.56.1 2.78 1.41 2.78 2.96 0 1.65-1.35 3-3 3H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.5-.95C8.08 7.14 9.94 6 12 6m0-2C9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96C18.67 6.59 15.64 4 12 4z"></path>
            </svg>
            <div
              className={css`
                position: absolute;
                width: 35px;
                display: flex;
                -webkit-box-align: center;
                align-items: center;
                justify-content: center;
                border-radius: 17px;
                left: 40.2px;
                top: 29.4px;
                bottom: 28px;
                z-index: 1;
                overflow: hidden;
                & {
                }
                @keyframes isUploading {
                  0% {
                    transform: translateY(0%);
                  }

                  100% {
                    transform: translateY(-50%);
                  }
                }
              `}
            >
              <div
                className={css`
                  animation: ${isPending &&
                  ` 1s cubic-bezier( 0.8, 0.3, 0.4, 0.8 ) 0s
							infinite normal none running isUploading;
					`};
                `}
              >
                <svg
                  width="38"
                  height="38"
                  viewBox="0 0 24 24"
                  className={css`
                    fill: ${answersColor.setAlpha(0.3).toString()};
                  `}
                >
                  <path d="M12 3.172l-6.414 6.414c-.781.781-.781 2.047 0 2.828s2.047.781 2.828 0l1.586-1.586v7.242c0 1.104.895 2 2 2 1.104 0 2-.896 2-2v-7.242l1.586 1.586c.391.391.902.586 1.414.586s1.023-.195 1.414-.586c.781-.781.781-2.047 0-2.828l-6.414-6.414z"></path>
                </svg>
                {isPending && (
                  <svg
                    width="38"
                    height="38"
                    viewBox="0 0 24 24"
                    className={css`
                      fill: ${answersColor.setAlpha(0.3).toString()};
                    `}
                  >
                    <path d="M12 3.172l-6.414 6.414c-.781.781-.781 2.047 0 2.828s2.047.781 2.828 0l1.586-1.586v7.242c0 1.104.895 2 2 2 1.104 0 2-.896 2-2v-7.242l1.586 1.586c.391.391.902.586 1.414.586s1.023-.195 1.414-.586c.781-.781.781-2.047 0-2.828l-6.414-6.414z"></path>
                  </svg>
                )}
              </div>
            </div>
          </>
        </div>
      ) : (
        <>
          {Object.entries(files).map(([fileKey, fileData]) => {
            // let colors = {
            // 	pending: 'black',
            // 	success: 'green',
            // 	failed: 'red',
            // };
            return (
              <div
                className={classnames(
                  `blocklib-file-block-${blockId}-display__uploaded-file`,
                  css`
                    opacity: 0;
                    transform: scale(0);
                    transition: all 0.2s ease-in-out;

                    &.active {
                      opacity: 1;
                      transform: scale(1);
                    }
                  `
                )}
                key={fileKey}
              >
                {fileData.previewUrlSrc &&
                (fileData.type === 'image/jpg' ||
                  fileData.type === 'image/png' ||
                  fileData.type === 'image/jpeg') ? (
                  <div
                    className={css`
                      border-radius: 5px;
                      overflow: hidden;
                      border: 1px solid ${answersColor.setAlpha(1).toString()};
                    `}
                  >
                    <img
                      className={css`
                        max-width: 100%;
                        height: 190px;
                        width: auto;
                        display: block;
                        object-fit: cover;
                      `}
                      src={fileData.previewUrlSrc}
                    />
                    <SingleFileInfo
                      fileData={fileData}
                      borderColor={answersColor.setAlpha(1).toString()}
                      color={theme.questionsColor}
                      fileKey={fileKey}
                      onDelete={(key) => {
                        document
                          .querySelector(`.blocklib-file-block-${blockId}-display__uploaded-file`)
                          ?.classList?.remove('active');

                        setTimeout(() => {
                          deleteFile(key);
                        }, 200);
                      }}
                    />
                  </div>
                ) : (
                  <div
                    className={css`
                      width: 200px;
                      border-radius: 5px;
                      overflow: hidden;
                      border: 1px solid ${answersColor.setAlpha(1).toString()};
                    `}
                  >
                    <div
                      className={css`
                        height: 200px;
                        width: 100%;
                        display: flex;
                        -webkit-box-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        justify-content: center;
                        background: ${answersColor.setAlpha(0.3).toString()};
                      `}
                    >
                      <FileIcon color={answersColor.setAlpha(1).toString()} />
                    </div>
                    <SingleFileInfo
                      fileData={fileData}
                      borderColor={answersColor.setAlpha(1).toString()}
                      color={theme.questionsColor}
                      fileKey={fileKey}
                      onDelete={(key) => deleteFile(key)}
                    />
                  </div>
                )}

                {/* { fileData.status === 'failed' && (
										<div>Error: { fileData.error }</div>
									) } */}
              </div>
            );
          })}
        </>
      )}
      {size(files) === 0 && (
        <>
          <div
            className={css`
              color: ${theme.questionsColor};
              font-size: 13px;
              strong:nth-child(1) {
                color: ${theme.answersColor};
              }
            `}
          >
            <strong> Choose file </strong> or <strong>Drag here</strong>
          </div>
          <div
            className={css`
              margin-top: 15px;
              font-size: 13px;
              color: ${theme.questionsColor};
            `}
          >
            Max File Size is {attributes.maxFileSize} MB
          </div>
        </>
      )}

      {Object.entries(files)[0]?.[1]?.progress < 100 && (
        <>
          <ProgressBar progress={Object.entries(files)[0][1]?.progress} />

          <div
            className={css`
              margin-top: 3px;
              color: ${theme.answersColor};
            `}
          >
            Uploading...
          </div>
        </>
      )}
    </div>
  );
};

export default UploadAreaContent;
