import './global.css';
import 'react-toastify/dist/ReactToastify.css';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { ENV } from './constants/common';
import * as serviceWorker from './serviceWorker';
// import reportWebVitals from './utils/reportWebVitals';
import Layout from './components/Layout';
import { createBrowserRouter, RouterProvider, useNavigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import { FunctionComponent, useEffect } from 'react';
import { useModalStateStore } from './state/modals';
// import ReactGA from 'react-ga4';
import './i18n/i18n';
import { useTranslation } from 'react-i18next';
import { ThemeProvider } from '@mui/material';
import { theme } from './constants/themes';
import { ToastContainer, toast } from 'react-toastify';
// import { AUTH_STATE, useAuthStore } from './state/authentication';
// import { usePreAuthenticationStore } from './state/preAuthentication';

// try {
//   ReactGA.initialize(ANALYTICS_ID, {
//     gaOptions: {
//       forceSSL: true,
//     },
//   });
// } catch (e) {}

// Sentry.init({
//   dsn: SENTRY_DSN,
//   enabled: SENTRY_ENABLED,
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0,
//   environment: SENTRY_ENV,
// });

const Contact: FunctionComponent<{ BaseComponent?: FunctionComponent }> = ({ BaseComponent }) => {
  const navigate = useNavigate();
  const { openContactUsModal } = useModalStateStore(({ openContactUsModal }) => ({
    openContactUsModal,
  }));

  useEffect(() => {
    openContactUsModal();
    navigate('/');
  }, []);

  return BaseComponent ? <BaseComponent /> : <Dashboard />;
};

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Dashboard />,
      },
      {
        path: '/contact',
        element: <Contact BaseComponent={Dashboard} />,
      },
    ],
    errorElement: <>Error</>,
  },
]);

const App = () => {
  const { i18n } = useTranslation();
  // const { init, authState } = useAuthStore(({ init, authState }) => ({ init, authState }));
  // const { newBooking, clearNewBooking } = usePreAuthenticationStore(
  //   ({ newBooking, clearNewBooking }) => ({
  //     newBooking,
  //     clearNewBooking,
  //   })
  // );
  // const { closeNewBookingModal } = useModalStateStore(({ closeNewBookingModal }) => ({
  //   closeNewBookingModal,
  // }));

  // useEffect(() => {
  //   if (authState === AUTH_STATE.UNKNOWN) init();
  // }, [authState]);

  // useEffect(() => {
  //   if (authState === AUTH_STATE.AUTHENTICATED && newBooking) {
  //     closeNewBookingModal();
  //     toast
  //       .promise(postNewBooking(newBooking), {
  //         pending: 'Submitting',
  //         error: 'There was an error creating your booking. Please try again.',
  //         success: 'Successfully added your booking',
  //       })
  //       .finally(clearNewBooking);
  //   }
  // }, [authState, newBooking]);

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.querySelector("meta[name='language']")?.setAttribute('content', i18n.language);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error("Could not get 'root' element");
else createRoot(rootElement).render(<App />);

// reportWebVitals();

if (ENV === 'dev' || ENV === 'prod') serviceWorker.register();
