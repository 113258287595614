import { AppBar, Box, Container, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// import logoImage from '../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MobileMenu from './menu/MobileMenu';
import FullMenu from './menu/FullMenu';
import { useState } from 'react';

const Header = () => {
  const { t } = useTranslation();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorElNav(event.currentTarget);
  };

  return (
    <AppBar
      key={'toolbar'}
      id="back-to-top-anchor"
      position="static"
      color="transparent"
      sx={{ zIndex: 10 }}
    >
      <Container sx={{ maxWidth: '1280px' }}>
        <Toolbar disableGutters>
          <Box
            onClick={() => navigate('/')}
            sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, cursor: 'pointer' }}
          >
            <Box
              component="img"
              sx={{ width: '36px', height: '36px', overflow: 'clip' }}
              //src={logoImage}
              //alt="Hotel Price Watch"
            />
          </Box>
          <Typography
            sx={{ display: { xs: 'none', md: 'flex', cursor: 'pointer' } }}
            onClick={() => navigate('/')}
            pl="5px"
          >
            {t('Resume AI')}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton aria-label="Open/Close Menu" onClick={handleOpenNavMenu}>
              <MenuIcon />
            </IconButton>

            <MobileMenu anchorElNav={anchorElNav} setAnchorElNav={setAnchorElNav} />
          </Box>

          <Box
            onClick={() => navigate('/')}
            sx={{ display: { xs: 'none', sm: 'flex', md: 'none' }, mr: 1, cursor: 'pointer' }}
          >
            <Box
              component="img"
              sx={{ width: '36px', height: '36px', overflow: 'clip' }}
              //src={logoImage}
              alt="Resume AI"
            />
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: '.1rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {t('Resume AI')}
          </Typography>
          <FullMenu />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
